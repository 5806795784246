define("ember-render-helpers/helpers/did-update", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * This helper is activated only on _updates_ to it's arguments (both positional
   * and named). It does not run during or after initial render, or before it is
   * un-rendered (removed from the DOM).
   */
  class DidUpdateHelper extends _helper.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "didRun", false);
    }

    compute(positional, named) {
      const fn = positional[0];
      (true && !(typeof fn === 'function') && (0, _debug.assert)(`\`{{did-update fn}}\` expects a function as the first parameter. You provided: ${fn}`, typeof fn === 'function'));

      if (!this.didRun) {
        this.didRun = true; // Consume individual properties to entangle tracking.
        // https://github.com/emberjs/ember.js/issues/19277
        // eslint-disable-next-line @typescript-eslint/no-empty-function

        positional.forEach(() => {});
        Object.values(named);
        return;
      }

      fn(positional.slice(1), named);
    }

  }

  _exports.default = DidUpdateHelper;
});